





















import { ProfileModel } from '@/models/profile-model'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  wallet = walletStore
  closeDialog() {
    this.wallet.changeOpenKycWarningDialog(false)
  }
}
